import React from 'react';
import './Footer.scss';

const Footer = () => {
    return (
        <>
            <div className="footer">
                <div className="container">
                    <p>2024 © SAM-ML. All rights reserved.</p>
                </div>
            </div>
        </>
    )
}

export default Footer
