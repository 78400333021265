import React from 'react';
import Plot from 'react-plotly.js';

const Scatterplot = ({ data }) => {
    if (!data || !data.data) {
        return <div>Loading...</div>;
    }

    const { x_opp, y_opp, texts_opp, x_pas, y_pas, texts_pas, distances, indexes } = data.data;

    const contourData = {
        x: [...x_pas, ...x_opp],
        y: [...y_pas, ...y_opp],
        type: 'histogram2dcontour',
        colorscale: 'Reds',
        opacity: 0.4,
        contours: {
            showlabels: true,
            labelfont: {
                size: 12,
                color: 'white'
            }
        },
        showlegend: false,
        showscale: false
    };

    const scatterOppData = {
        x: x_opp,
        y: y_opp,
        mode: 'markers',
        type: 'scatter',
        name: 'Opportunity',
        text: texts_opp.map(text => `${text.substring(0, 100)}...<br>${text.substring(101, Math.min(200, text.length))}`),
        hoverinfo: 'text+x+y',
        marker: {
            color: 'blue',
            size: 7,
            opacity: 0.5
        }
    };

    const scatterPasData = {
        x: x_pas,
        y: y_pas,
        mode: 'markers',
        type: 'scatter',
        name: 'Past Performance',
        text: texts_pas.map(text => `${text.substring(0, 100)}...<br>${text.substring(101, Math.min(200, text.length))}`),
        hoverinfo: 'text+x+y',
        marker: {
            color: 'green',
            size: 7,
            opacity: 0.5
        }
    };

    const scatterLayout = {
        hovermode: 'closest',
        xaxis: { title: '' },
        yaxis: { title: '' },
        autosize: true,
        margin: { l: 50, r: 50, b: 50, t: 50 },
        showlegend: true,
    };

    const histogramData = {
        x: distances,
        type: 'histogram',
        marker: {
            color: 'rgba(100, 200, 102, 0.7)',
            line: {
                color: 'rgba(100, 200, 102, 1)',
                width: 1,
            },
        },
        opacity: 0.75,
    };

    const histogramLayout = {
        title: 'Histogram of Distances',
        xaxis: { title: 'Distance' },
        yaxis: { title: 'Count' },
        autosize: true,
        margin: { l: 50, r: 50, b: 50, t: 50 },
        dragmode: 'select', // Enable selection tool by default
        selectdirection: 'h', // Restrict selection to horizontal direction
    };

    const scatterConfig = {
        responsive: true,
        displaylogo: false,
        displayModeBar: true, // Keep all tools for scatter plot
    };

    const histogramConfig = {
        responsive: true,
        displaylogo: false,
        displayModeBar: true, // Always show mode bar
        modeBarButtonsToRemove: [
            'zoomIn2d',
            'zoomOut2d',
            'pan2d',
            'autoScale2d',
            'lasso2d',
            'hoverClosestCartesian',
            'hoverCompareCartesian',
            'toggleSpikelines'
        ], // Only keep reset and box select tools
    };

    return (
        <div>
            <Plot
                data={[contourData, scatterOppData, scatterPasData]}
                layout={scatterLayout}
                style={{ width: '100%', height: '50%' }}
                config={scatterConfig}
            />
            <Plot
                data={[histogramData]}
                layout={histogramLayout}
                style={{ width: '100%', height: '50%' }}
                config={histogramConfig}
            />
        </div>
    );
}

export default Scatterplot;
