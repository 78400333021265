import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
// import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { Amplify } from 'aws-amplify';
import config from './aws-exports';  // Ensure this path is correct
import './App.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from './Components/Header/Header';
import Footer from './Components/Footer/Footer';
import Dashboard from './pages/Dashboard/Dashboard';
import Management from './pages/DocumentManagement/Management';
import Performance from './pages/PastPerformance/Performance';
import Opportunity from './pages/OpportunityParser/Opportunity';
import Mapping from './pages/PerformanceMapping/Mapping';
import { withAuthenticator } from '@aws-amplify/ui-react';

Amplify.configure(config);

function App() {
  return (
    <>
        <BrowserRouter>
        <Header />
            <Routes>
              <Route path="/" element={<Dashboard />} />
              <Route path="/performance-mapper" element={<Mapping />} />
              <Route path="/document-management" element={<Management />} />
              <Route path="/past-performance" element={<Performance />} />
              <Route path="/opportunity-parser" element={<Opportunity />} />
            </Routes>
        <Footer />
        </BrowserRouter>
    </>
  );
}

export default withAuthenticator(App);
