import React from 'react';
import Plot from 'react-plotly.js';

const HistogramChart = ({ similarities, verticalLineLocation }) => {
    // Ensure similarities is an array
    if (!Array.isArray(similarities)) {
        return <div>No data available</div>;
    }

    // Calculate the bins and counts
    const binSize = 0.05; // Adjust bin size as needed
    const bins = [];
    const counts = [];

    similarities.forEach(similarity => {
        const bin = Math.floor(similarity / binSize) * binSize;
        const binIndex = bins.indexOf(bin);
        if (binIndex >= 0) {
            counts[binIndex]++;
        } else {
            bins.push(bin);
            counts.push(1);
        }
    });

    // Create the trace for the histogram
    const trace = {
        x: bins,
        y: counts,
        type: 'bar',
        marker: {
            color: 'blue',
        },
    };

    const layout = {
        title: 'Similarity Histogram',
        xaxis: {
            title: 'Similarity',
            tickmode: 'linear',
            dtick: binSize,
        },
        yaxis: {
            title: 'Count',
        },
        showlegend: false,
        shapes: [
            {
                type: 'line',
                x0: verticalLineLocation,
                x1: verticalLineLocation,
                y0: 0,
                y1: Math.max(...counts),
                line: {
                    color: 'red',
                    width: 2,
                },
            },
        ],
    };

    const config = {
        displayModeBar: false,
    };

    return (
        <Plot
            data={[trace]}
            layout={layout}
            config={config}
            useResizeHandler
            style={{ width: '100%', height: '100%' }}
        />
    );
};

export default HistogramChart;
