import React, { useState, useEffect, useRef } from 'react';
import './Mapping.scss';
import Scatterplot from '../../Components/Scatterplot/Scatterplot'; 
import { post, isCancelError } from '@aws-amplify/api';
import { fetchUserAttributes } from 'aws-amplify/auth';
import { v4 as uuidv4 } from 'uuid';
import { Oval } from 'react-loader-spinner';
import PDF from 'react-pdf-js';

const Mapping = () => {
    const [ScatterplotData, setScatterplotData] = useState([]);
    const [secretName, setSecretName] = useState(null);
    const [loading, setLoading] = useState(true);
    const [selectedPoint, setSelectedPoint] = useState(null);
    const [pdfData, setPdfData] = useState(null);
    const [page, setPage] = useState(1);  
    const [totalPages, setTotalPages] = useState(null);  
    const [pdfLoading, setPdfLoading] = useState(false); 
    const [scale, setScale] = useState(1); 
    const viewerRef = useRef(null); 

    const handlePointClick = async (pointData) => {
        const index = ScatterplotData.x.findIndex((xValue, i) => {
            return xValue === pointData.x && ScatterplotData.y[i] === pointData.y;
        });
    
        if (index === -1) {
            console.error('No matching data found for the clicked point');
            return;
        }
    
        const key = ScatterplotData.keys[index];
        const page = parseInt(ScatterplotData.pages[index], 10);

        setSelectedPoint(pointData);
        setPdfLoading(true);

        try {
            const fetchDocData = await post({ 
                apiName: 'DataFlow',
                path: '/PullDocument',
                options: {
                    body: {
                        process_id: uuidv4(),
                        role_arn: "arn:aws:iam::637423610310:role/SAMML-Application-Role",
                        bucket: "examplecompanybucket",
                        key: key
                    },
                }
            });

            const response = await fetchDocData.response;
            const bodyData = await response.body.json();
            const base64Data = bodyData.data; 
            const byteCharacters = atob(base64Data);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], { type: 'application/pdf' });
            const pdfUrl = URL.createObjectURL(blob);
            setPdfData(pdfUrl);

            setPage(page);  
            setTotalPages(null); 
        } catch (error) {
            console.error('Error fetching document:', error);
        } finally {
            setPdfLoading(false);
        }
    };

    const changePage = (offset) => {
        setPage(prevPage => prevPage + offset);
    };

    const handleDocumentLoad = (pages) => {
        setTotalPages(pages);
        adjustScale();
    };

    const adjustScale = () => {
        if (viewerRef.current) {
            const viewerWidth = viewerRef.current.offsetWidth;
            const viewerHeight = viewerRef.current.offsetHeight;
            const pageWidth = viewerWidth / scale;
            const pageHeight = viewerHeight / scale;
            const newScale = Math.min(viewerWidth / pageWidth, viewerHeight / pageHeight);
            setScale(newScale);
        }
    };

    useEffect(() => {
        async function getUserAttributes() {
            try {
                const user = await fetchUserAttributes();
                setSecretName(user['custom:Secret_Name']);
            } catch (error) {
                console.log('Error fetching user attributes', error);
            }
        }
        getUserAttributes();
    }, []);

    useEffect(() => {
        if (!secretName) return;

        const fetchMappingData = async () => {
            try {
                // const fetchCompanyData = post({ 
                //     apiName: 'DataFlow',
                //     path: '/ReturnInfo',
                //     options: {
                //         body: {
                //             company_uuid: companyUUID,
                //             payload: '',
                //             process_id: '1',
                //             presigned: 'no',
                //             keys: 'filekeys'
                //         },
                //     }
                // });
                // const companyResponse = await fetchCompanyData.response;
                // const companyData = await companyResponse.body.json();

                const fetchDocData = post({ 
                    apiName: 'DataFlow',
                    path: '/PastMapper',
                    options: {
                        body: {
                            process_id: uuidv4(),
                            Secret_Name: secretName,
                        },
                    }
                });
                const response = await fetchDocData.response;
                return response;
            } catch (error) {
                if (isCancelError(error)) {
                    console.log('Request cancelled');
                } else {
                    console.error('Failed to fetch data:', error);
                }
            }
        };

        const parseData = async () => {
            try {
                setLoading(true);
                const response = await fetchMappingData();
                if (response) {
                    const responseData = await response.body.json();
                    if (responseData && responseData.data && Array.isArray(responseData.data.names)) {
                        setScatterplotData(responseData.data);
                    } else {
                        console.log('No data received or data is not in expected format');
                    }
                } else {
                    console.log('No response received');
                }
            } catch (error) {
                console.error('Error parsing data:', error);
            } finally {
                setLoading(false);
            }
        };

        parseData();
    }, [secretName]);

    return (
        <div className="dashboard-screen">
            <div className="container-fluid">
                <div className="main-title">
                    <h1>Performance Mapper</h1>
                </div>
                {loading ? (
                    <div className="charts-section">
                        <div className="row">
                            <div className="col-12">
                                <div className="map-chart-box">
                                    <div className="loader-container">
                                        <Oval color="#00BFFF" height={80} width={80} />
                                        <h4>This may take a few minutes...</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="charts-section">
                        <div className="row">
                            <div className="col-8"> {/* Adjust the column width as needed */}
                                <div className="map-chart-box">
                                    <Scatterplot data={ScatterplotData} onPointClick={handlePointClick} />
                                </div>
                            </div>
                            <div className="col-4"> {/* Adjust the column width as needed */}
                                <div className="map-chart-box" ref={viewerRef}> {/* Added a box with the same CSS as charts section */}
                                    {pdfLoading ? (
                                        <div className="loader-container">
                                            <Oval color="#00BFFF" height={60} width={60} />
                                            <h4>Loading document...</h4>
                                        </div>
                                    ) : selectedPoint ? (
                                        <div className="selected-point-info">
                                            {pdfData && (
                                                <div className="pdf-viewer">
                                                    <PDF
                                                        file={pdfData}
                                                        page={page}
                                                        onDocumentComplete={handleDocumentLoad}
                                                        scale={scale}
                                                    />
                                                    <div className="pdf-controls">
                                                        <button onClick={() => changePage(-1)} disabled={page === 1}>
                                                            Previous Page
                                                        </button>
                                                        <button onClick={() => changePage(1)} disabled={page === totalPages || !totalPages}>
                                                            Next Page
                                                        </button>
                                                        <p>Page {page} of {totalPages || '...loading'}</p>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    ) : (
                                        <div className="placeholder">
                                            <p style={{ color: '#555', fontSize: '16px', textAlign: 'center' }}>Please click on a data point to view the associated document.</p>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Mapping;
