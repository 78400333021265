import React from 'react';
import Plot from 'react-plotly.js';

const Scatterplot = ({ data, onPointClick }) => {
    if (!data || !data.x || !data.y || !data.topics || !data.labels) {
        console.log('Data not available');
        return <div>Loading...</div>;
    }

    const uniqueTopics = [...new Set(data.topics)];
    const colors = ['red', 'green', 'blue', 'purple', 'orange', 'yellow', 'cyan', 'magenta'];
    const topicColorMap = uniqueTopics.reduce((acc, topic, index) => ({
        ...acc,
        [topic]: colors[index % colors.length]
    }), {});

    const formattedTexts = data.labels.map((label, index) =>
        `${label.substring(0, 100)}...<br>${label.substring(101, Math.min(200, label.length))}`
    );

    const contourData = {
        x: data.x,
        y: data.y,
        type: 'histogram2dcontour',
        colorscale: 'Reds',
        opacity: 0.4,
        contours: {
            showlabels: true,
            labelfont: {
                size: 12,
                color: 'white'
            }
        },
        showlegend: false,
        showscale: false 
    };

    const scatterData = uniqueTopics.map(topic => ({
        x: data.x.filter((_, i) => data.topics[i] === topic),
        y: data.y.filter((_, i) => data.topics[i] === topic),
        mode: 'markers',
        type: 'scatter',
        name: topic,
        text: formattedTexts.filter((_, i) => data.topics[i] === topic),
        hoverinfo: 'text+x+y',
        marker: {
            color: topicColorMap[topic],
            size: 7,
            opacity: 0.5
        }
    }));

    const layout = {
        hovermode: 'closest',
        xaxis: { title: '' },
        yaxis: { title: '' },
        autosize: true,
        margin: { l: 50, r: 50, b: 50, t: 50 },
        showlegend: true,
    };

    const handleClick = (event) => {
        const pointData = event.points[0]; 
        if (onPointClick) {
            onPointClick(pointData); 
        }
    };

    return (
        <Plot
            data={[contourData, ...scatterData]}
            layout={layout}
            style={{ width: '100%', height: '100%' }}
            config={{ responsive: true, displaylogo: false }}
            onClick={handleClick}
        />
    );
};

export default Scatterplot;
