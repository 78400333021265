import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faSearch, faBell, faCog } from '@fortawesome/free-solid-svg-icons';
import { faCog } from '@fortawesome/free-solid-svg-icons';
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import './Header.scss';
// import user from '../../assets/images/user-icon.png';
import logo from '../../assets/images/SAM-ML Logo.png';

const Header = () => {
    const location = useLocation();
    const [activeLink, setActiveLink] = useState('');
    const userLetter = "S"; 

    useEffect(() => {
        const pathToLinkName = {
            '/': 'SAM Dashboard',
            '/performance-mapper': 'Performance Mapper',
            '/document-management': 'Document Management',
            '/opportunity-parser': 'Opportunity Parser',
            '/past-performance': 'Past Performance'
        };
        setActiveLink(pathToLinkName[location.pathname] || 'SAM Dashboard');
    }, [location.pathname]);

    const handleNavLinkClick = (linkName) => {
        setActiveLink(linkName);
    };

    return (
        <>
            <Navbar expand="lg" className="header">
                <Container fluid>
                    <Link to='/' className='navbar-brand'>
                        <Image src={logo} alt="SAM-ML Logo" className="logo" />
                    </Link>
                    <Navbar.Toggle aria-controls="navbarScroll" />
                    <Navbar.Collapse id="navbarScroll">
                        <Nav className="me-auto ms-auto my-2 my-lg-0">
                            <Link to='/' className={`nav-link ${activeLink === 'SAM Dashboard' ? 'active' : ''}`}
                                onClick={() => handleNavLinkClick('SAM Dashboard')}
                            >
                                SAM Dashboard
                            </Link>
                            <Link to='/performance-mapper' className={`nav-link ${activeLink === 'Performance Mapper' ? 'active' : ''}`}
                                onClick={() => handleNavLinkClick('Performance Mapper')}
                            >
                                Performance Mapper
                            </Link>
                            <Link to='/document-management' className={`nav-link ${activeLink === 'Document Management' ? 'active' : ''}`}
                                onClick={() => handleNavLinkClick('Document Management')}
                            >
                                Document Management
                            </Link>
                            <Link to='/opportunity-parser' className={`nav-link ${activeLink === 'Opportunity Parser' ? 'active' : ''}`}
                                onClick={() => handleNavLinkClick('Opportunity Parser')}
                            >
                                Opportunity Parser
                            </Link>
                            <Link to='/past-performance' className={`nav-link ${activeLink === 'Past Performance' ? 'active' : ''}`}
                                onClick={() => handleNavLinkClick('Past Performance')}
                            >
                                Past Performance
                            </Link>
                        </Nav>
                        <div className="d-flex right-bar align-items-center">
                            <div className="column">
                                <Nav className="d-flex align-items-center">
                                    {/* <Link to='/' className='nav-link'>
                                        <FontAwesomeIcon icon={faSearch} />
                                    </Link> */}
                                    {/* <Link to='/' className='nav-link'>
                                        <FontAwesomeIcon icon={faBell} />
                                    </Link> */}
                                </Nav>
                            </div>
                            <div className="column">
                                <Nav className="d-flex align-items-center">
                                    <Link to='/' className='nav-link'>
                                        <FontAwesomeIcon icon={faCog} />
                                    </Link>
                                    <Link to='/' className='nav-link'>
                                        <div className="user-letter">
                                            {userLetter}
                                        </div>
                                    </Link>
                                </Nav>
                            </div>
                        </div>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </>
    )
}

export default Header;
