import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import './CustomPagination.scss';

const CustomPagination = ({ currentPage, totalPages, onPageChange }) => {
    const [localCurrentPage, setLocalCurrentPage] = useState(currentPage); // Local state to track currentPage

    useEffect(() => {
        // Update localCurrentPage when currentPage prop changes
        setLocalCurrentPage(currentPage);
        // console.log('Local current page updated to:', localCurrentPage);
    }, [currentPage]);

    const handlePageChange = (page) => {
        onPageChange(page);
        // console.log('page',page)
        setLocalCurrentPage(page); // Update localCurrentPage when page changes
    };
    // const handlePageChange = (page) => {
    //     console.log("Current local page before change:", localCurrentPage);
    //     console.log("New page request:", page);
    //     if (page !== localCurrentPage && page >= 1 && page <= totalPages) {
    //         onPageChange(page);
    //         setLocalCurrentPage(page); // Update localCurrentPage when page changes
    //         console.log("Local page set to:", page);
    //     }
    // };
    

    const renderPageButtons = () => {
        const buttons = [];
        // console.log('local')
        // console.log(localCurrentPage)
        for (let i = 1; i <= totalPages; i++) {
            if (i === localCurrentPage) {
                // console.log('active', i)
                buttons.push(
                    <button key={i} onClick={() => handlePageChange(i)} className={'active'}>
                        {i}
                    </button>
                );
            } else {
                // console.log('inactive', i)
                buttons.push(
                    <button key={i} onClick={() => handlePageChange(i)} className={'inactive'}>
                        {i}
                    </button>
                );
            }
        }
        return buttons;
    };

    return (
        <div className="custom-pagination">
            <button className="arrows" onClick={() => handlePageChange(localCurrentPage - 1)} disabled={localCurrentPage === 1}><FontAwesomeIcon icon={faArrowLeft} /></button>
            {renderPageButtons()}
            <button className="arrows" onClick={() => handlePageChange(localCurrentPage + 1)} disabled={localCurrentPage === totalPages}><FontAwesomeIcon icon={faArrowRight} /></button>
        </div>
    );
};

CustomPagination.propTypes = {
    currentPage: PropTypes.number.isRequired,
    totalPages: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
};

export default CustomPagination;

// import React from 'react';
// import PropTypes from 'prop-types';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
// import './CustomPagination.scss';

// const CustomPagination = ({ currentPage, totalPages, onPageChange }) => {
//     const handlePageChange = (page) => {
//         onPageChange(page);
//         console.log('pag',page)
//         // console.log(page)
//     };

//     const renderPageButtons = () => {
//         const buttons = [];
//         for (let i = 1; i <= totalPages; i++) {
//             if ( i === currentPage ) {
//                 console.log('cur',currentPage)
//                 console.log('i',i)
//                 buttons.push(
//                     <button key={i} onClick={() => handlePageChange(i)} className={ 'active' }>
//                         {i}
//                     </button>
//                 );
//             } else {
//                 console.log('cur',currentPage)
//                 console.log('i',i)
//                 buttons.push(
//                     <button key={i} onClick={() => handlePageChange(i)} className={ 'inactive' }>
//                         {i}
//                     </button>
//                 );
//             }
//         }
//         console.log(buttons)
//         console.log(currentPage)
//         return buttons;
//     };

//     return (
//         <div className="custom-pagination">
//             <button className="arrows" onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}><FontAwesomeIcon icon={faArrowLeft} /></button>
//             {renderPageButtons()}
//             <button className="arrows" onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages}><FontAwesomeIcon icon={faArrowRight} /></button>
//         </div>
//     );
// };

// CustomPagination.propTypes = {
//     currentPage: PropTypes.number.isRequired,
//     totalPages: PropTypes.number.isRequired,
//     onPageChange: PropTypes.func.isRequired,
// };

// export default CustomPagination;
