import React from 'react';
import Plot from 'react-plotly.js';

const calculateAverageSimilarities = (data) => {
    const topicSums = {};
    const topicCounts = {};

    data.metas.forEach((meta, index) => {
        const similarity = data.similarities[index];
        const topic = meta.topic;
        
        if (!topicSums[topic]) {
            topicSums[topic] = 0;
            topicCounts[topic] = 0;
        }

        topicSums[topic] += similarity;
        topicCounts[topic] += 1;
    });

    const topicAverages = Object.keys(topicSums).map(topic => ({
        topic,
        average: topicSums[topic] / topicCounts[topic]
    }));

    return topicAverages;
};


const BarGraph = ({ data }) => {
    if (!data || !data.metas || !data.similarities) {
        return <div>No data available</div>;
    }

    let topicAverages = calculateAverageSimilarities(data);

    topicAverages = topicAverages.sort((a, b) => a.average - b.average);

    const xLabels = topicAverages.map(item => item.topic.replace(/ /g, '<br>'));

    const trace = {
        x: xLabels,
        y: topicAverages.map(item => item.average),
        type: 'bar',
        marker: {
            color: 'green',
        },
    };

    const layout = {
        title: 'Average Similarities by Topic',
        xaxis: {
            title: 'Topic',
            tickvals: xLabels,
            ticktext: xLabels,
        },
        yaxis: {
            title: 'Average Similarity',
        },
        showlegend: false,
    };

    const config = {
        displayModeBar: false,
    };

    return (
        <Plot
            data={[trace]}
            layout={layout}
            config={config}
            useResizeHandler
            style={{ width: '100%', height: '100%' }}
        />
    );
};

export default BarGraph;
