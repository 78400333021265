import React from 'react';
import Plot from 'react-plotly.js';

const ViolinChart = ({ data, past }) => {
    // console.log('stuff', past);

    const average = past[0];
    const stdev = past[1];

    const traces = data.map((item, index) => {
        return {
            type: 'violin',
            y: [
                item.q1,
                item.q2 - item.stedv,
                item.q2,
                item.q2 + item.stedv,
                item.q3
            ],
            points: 'all',
            jitter: 0,
            pointpos: 0,
            box: {
                visible: true
            },
            line: {
                color: 'blue'
            },
            meanline: {
                visible: true
            },
            fillcolor: '#41ED32',
            opacity: 0.6,
            x0: item.pos
        };
    });

    const layout = {
        title: 'Summary Statistics of Opportunities',
        yaxis: {
            zeroline: false,
            title: 'Cosine Distance'
        },
        xaxis: {
            title: 'Opportunity',
            tickmode: 'array',
            tickvals: data.map((item) => item.pos),
            ticktext: data.map((item) => item.pos)
        },
        showlegend: false,
        shapes: [
            
            {
                type: 'rect',
                x0: Math.min(...data.map(item => item.pos)),
                x1: Math.max(...data.map(item => item.pos)),
                y0: average - stdev,
                y1: average + stdev,
                fillcolor: 'rgba(128, 0, 128, 0.2)',
                line: {
                    width: 0
                },
                layer: 'below'
            },
            
            {
                type: 'line',
                x0: Math.min(...data.map(item => item.pos)),
                x1: Math.max(...data.map(item => item.pos)),
                y0: average,
                y1: average,
                line: {
                    color: 'purple',
                    width: 2,
                    dash: 'dashdot'
                },
                layer: 'below'
            },
            
            {
                type: 'line',
                x0: Math.min(...data.map(item => item.pos)),
                x1: Math.max(...data.map(item => item.pos)),
                y0: average + stdev,
                y1: average + stdev,
                line: {
                    color: 'purple',
                    width: 2,
                    dash: 'dot'
                },
                layer: 'below'
            },
            
            {
                type: 'line',
                x0: Math.min(...data.map(item => item.pos)),
                x1: Math.max(...data.map(item => item.pos)),
                y0: average - stdev,
                y1: average - stdev,
                line: {
                    color: 'purple',
                    width: 2,
                    dash: 'dot'
                },
                layer: 'below'
            }
        ]
    };

    const config = {
        displayModeBar: false
    };

    return (
        <Plot
            data={traces}
            layout={layout}
            config={config}
            useResizeHandler
            style={{ width: '100%', height: '100%' }}
        />
    );
};

export default ViolinChart;
